"use strict";

import axios from "axios";

const config = {
  baseURL: process.env.VUE_APP_BASE_URL || "http://localhost:8000",
};

const _axios = axios.create(config);

const token = localStorage.getItem("token");

delete _axios.defaults.headers.common.Authorization;

if (token) {
  _axios.defaults.headers.common.Authorization = `Token ${token}`;
}

_axios.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log("Erro", error.status);
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default _axios;
