import store from "@/store";

export default async function guard(to, from, next) {
  if (
    to.matched.some((record) =>
      typeof record.meta.requiresAuth === "undefined" ? true : record.meta.requiresAuth
    )
  ) {
    try {
      await store.dispatch("auth/doCheck");
      if (from.name === "login") {
        next("/");
      } else {
        next();
      }
    } catch (e) {
      next("/login");
    }
  } else if (to.name === "login") {
    next("/");
  } else {
    next();
  }
}
