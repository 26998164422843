import store from "@/store";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/auth/login.vue"),
    meta: {
      requiresAuth: false,
      layout: "layout-auth",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      requiresAuth: false,
    },
    async beforeEnter(to, from, next) {
      await store.dispatch("auth/doSignOut");
      next("/login");
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: () => import(/* webpackChunkName: "detail" */ "@/views/home/detail.vue"),
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () =>
      import(/* webpackChunkName: "subscription" */ "@/views/subscription/index.vue"),
  },
  {
    path: "/awards",
    name: "Awards",
    component: () => import(/* webpackChunkName: "award" */ "@/views/award/index.vue"),
  },
  {
    path: "/championship",
    name: "Championship",
    component: () =>
      import(/* webpackChunkName: "championship" */ "@/views/championship/index.vue"),
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import(/* webpackChunkName: "videos" */ "@/views/videos/index.vue"),
  },
];
