import Vue from "vue";
import "devextreme/dist/css/dx.dark.compact.css";
import "@/assets/devextreme.css";
import "devextreme/dist/js/localization/dx.messages.pt";
import ptMessage from "devextreme/localization/messages/pt";
import { locale, loadMessages } from "devextreme/localization";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxTagBox } from "devextreme-vue/tag-box";
import { DxTextArea } from "devextreme-vue/text-area";

Vue.component(DxFileUploader);
Vue.component(DxTagBox);
Vue.component(DxTextArea);

loadMessages(ptMessage);
locale("pt");
