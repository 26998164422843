<template>
  <div>
    <app-loader />

    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      :clipped="$vuetify.breakpoint.lgAndUp"
      width="180"
      dark
      app
      mini-variant
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in items.filter((f) => (f.hasOwnProperty('isAdmin') ? f.isAdmin : true))"
          :key="item.title"
          :to="item.to"
          link
          color="secondary"
          class="hover:text-yellow-bt"
        >
          <v-list-item-icon>
            <v-icon class="text-yellow-bt">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      flat
      dense
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      v-if="$store.state.auth.user"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <div style="width: 140px" v-if="!isHome">
        <v-img src="/images/logo_basic.svg" alt="" class="mr-4 w-[40px]" />
      </div>

      <v-spacer />

      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" text tile>
            <span class="mr-3">{{ $store.state.auth.user.username }}</span>
            <v-avatar v-bind="attrs" size="36" dark>
              <v-img
                src="/images/atendente.jpg"
                :alt="$store.state.auth.user.username"
                :key="idx"
                :eager="true"
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in menu" :key="index" :to="item.to" link>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-icon v-if="item.icone">
              <v-icon :color="item.iconeCor">{{ item.icone }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import AppLoader from "@/components/loader.vue";
import bus from "@/plugins/bus";

export default {
  components: {
    AppLoader,
  },
  data() {
    return {
      drawer: true,
      idx: 0,
      menu: [
        {
          title: "Sair",
          to: "/logout",
          icone: "mdi-close",
          iconeCor: "red",
        },
      ],
    };
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    items() {
      return [
        {
          title: "Dashboard",
          icon: "mdi-poll-box",
          to: "/",
        },
        {
          title: "Inscrições",
          icon: "mdi-account-check",
          to: "/subscription",
        },
        {
          title: "Campeonato",
          icon: "mdi-fish",
          to: "/championship",
        },
        {
          title: "Videos",
          icon: "mdi-youtube",
          to: "/videos",
        },
        {
          title: "Sorteio",
          icon: "mdi-trophy",
          to: "/awards",
        },
      ];
    },
  },
  watch: {
    $route() {
      this.drawer = false;
    },
  },
  mounted() {
    bus.$on("updateImg", () => {
      this.idx += 1;
    });
  },
};
</script>
