import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0F172A",
        secondary: "#fcca50",
        accent: "#4338ca",
        error: "#be123c",
        info: "#0284c7",
        success: "#15803d",
        warning: "#b45309",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
