<template>
  <v-app id="app" class="bg-gradient-to-b from-primary via-primary to-slate-800 text-neutral-400">
    <AppBar />

    <v-main class="dx-viewport">
      <v-container fluid grid-list-lg>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/navbar.vue";

export default {
  components: {
    AppBar,
  },
};
</script>
